import { useUser } from "../../auth/UserContext";
import NotificationsMenu from "../modals/NotificationsModal";
import UserMenu from "./Sidebar";
import qricon from "../../images/qricon.png"

const HomeBanner = ({ title }) => {
  const user = useUser();

  return (
    <div className={`flex flex-col w-screen ${user.Authorized ? "min-h-[150px]" : "min-h-[110px]"} bg-sunburst px-8`}>
      <div className="w-full flex justify-between mt-6 items-start">
        <div className="flex items-center">
          <UserMenu />
        </div>
        <div className="flex items-center gap-2 pr-2">
          {/* <img src={qricon} className="h-[36px]"/> */}
          <NotificationsMenu />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
