import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { penpalServerApi } from "./penpalServerAPI";
import authReducer from './authSlice';

export const store = configureStore({
  reducer: {
    [penpalServerApi.reducerPath]: penpalServerApi.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      penpalServerApi.middleware,
    ]),
});

setupListeners(store.dispatch);
