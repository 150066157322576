import { useEffect, useRef, useState } from "react";
import ModalWrapper from "../modals/ModalWrapper";
import { useUser } from "../../auth/UserContext";
import LightSVG from "../../svg/LightSVG";
import DotsSVG from "../../svg/DotsSVG";
import NoLightSVG from "../../svg/NoLightSVG";
import ledIcon from '../../icons/light.png'
import { useGetAllBuildingsAndRoomsV1Query, useLazyActivateDeviceLightV1Query, usePostSessionLogV1Mutation } from "../../store/penpalServerAPI";
import LoadingScreen from "../global/LoadingScreen";

const LEDButton = ({ id, location, sessionLog, session, setUpdatedLocation, type }) => {
  const user = useUser();
  const [waiting, setWaiting] = useState(false);
  const [found, setFound] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [buttonText, setButtonText] = useState("Find Cage");
  const [smButtonColor, setSmButtonColor] = useState("bg-green-400");
  const [mdButtonColor, setMdButtonColor] = useState("bg-slate-300");
  const [lgButtonColor, setLgButtonColor] = useState("bg-slate-300 dark:bg-slate-500");
  const [show, setShow] = useState(false);
  const [lightStatus, setLightStatus] = useState("On");
  const [inputMode, setInputMode] = useState(false);
  const [racks, setRacks] = useState('');
  const [rack, setRack] = useState('');
  const [columns, setColumns] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']);
  const [column, setColumn] = useState('');
  const [rows, setRows] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [row, setRow] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [building, setBuilding] = useState('');
  const [buildingId, setBuildingId] = useState(sessionLog[0]?.building?.buildingId);
  const [rooms, setRooms] = useState([]);
  const [roomId, setRoomId] = useState(sessionLog.roomId);
  const [room, setRoom] = useState('');
  const [LEDState, setLEDState] = useState({});
  const [error, setError] = useState('');

  const {
    data: locationData
  } = useGetAllBuildingsAndRoomsV1Query();

  const [postSessionLog, {
    isError: postSessionIsError,
    error: postSessionError,
    isLoading: postSessionIsLoading,
    isSuccess: postSessionIsSuccess
  }] = usePostSessionLogV1Mutation();

  const [activateLight, { data: lightData }
  ] = useLazyActivateDeviceLightV1Query();

  const openLocationMenu = () => {
    setBuilding(location.building);
    setBuildingId(location.buildingId);
    setRoom(location.room);
    setRoomId(location.roomId);
    setRack(location.rack);
    setRow(location.row);
    setColumn(location.column);
  };

  const buttonState = {
    "OFF": {
      status: "Off",
      classNames: "border-gray-400 bg-gray-300 shadow-md",
      icon: <LightSVG className="text-[#000]" />
    },
    "ON": {
      status: "On",
      classNames: "border-green-500 bg-green-400 shadow-md",
      icon: <LightSVG className="text-white" />
    },
    "WAITING": {
      status: "Waiting",
      classNames: "border-gray-400 bg-gray-500 shadow-inner",
      icon: <DotsSVG classDot1="animate-flash" classDot2="animate-flash-2" classDot3="animate-flash-4" />
    },
    "UNABLE": {
      status: "Off",
      classNames: "border-red-400 bg-gray-300 shadow-md",
      icon: <NoLightSVG classCross="text-[#F00]" />
    },
  }

  useEffect(() => {
    setLEDState(buttonState["OFF"]);
  }, [])

  function newLogData() {
    if (location.buildingId != buildingId) return true;
    if (location.roomId != roomId) return true;
    if (location.rack != rack) return true;
    if (location.row != row) return true;
    if (location.column != column) return true;
    return false;
  }

  const submitDeviceLocation = async () => {
    if (!rack.length) {
      setError('Rack required.')
    } else {
      let log = {
        SessionId: sessionLog.sessionId,
        BuildingId: buildingId,
        RoomId: roomId,
        AnimalType: sessionLog.animalType,
        BreedType: sessionLog.breedType,
        AnimalCountF: sessionLog.animalCountF,
        AnimalCountM: sessionLog.animalCountM,
        Rack: rack === 'None' ? 'null' : rack,
        RackColumn: column ? column : 'null',
        RackRow: row ? row : 'null'
      };
      if (newLogData()) {
        let res = await postSessionLog({
          Session:
          {
            SessionId: sessionLog.sessionId,
            CageCardId: session.cageCardId,
            Name: session.name
          }, SessionLog: log
        }).unwrap()
        setInputMode(false);
        setShow(false);
        setUpdatedLocation(true)
      } else {
        setError('Error: Location Data has not been changed.')
      }
    }
  }

  useEffect(() => {
    if (postSessionIsSuccess) setUpdatedLocation(true)
    if (postSessionIsError) console.log('POST SESSION ERROR: ', postSessionError)
  }, [postSessionIsSuccess, postSessionIsError])

  function UpdateButton() {
    setInputMode(!inputMode);
  };

  useEffect(() => {
    if (locationData === undefined || locationData === null) return;
    else {
      setBuildings(Object.keys(locationData))
    }
  }, [locationData])

  useEffect(() => {
    if (!building && buildings) {
      setBuilding(buildings[0])
    }
  }, [buildings])

  function selectBuilding(buildingName) {
    setBuildingId(locationData[buildingName].id)
    setBuilding(buildingName)
    if (locationData[buildingName].rooms) {
      setRooms(locationData[buildingName].rooms)
    } else {
      setRooms([])
    }
  };

  function selectRoom(roomId) {
    setRoomId(roomId)
  }

  useEffect(() => {
    if (!locationData || !building) return;
    if (building.length) {
      setBuildingId(locationData[building].id)
    }
    if (locationData[building].rooms) {
      setRooms(locationData[building].rooms)
    } else {
      setRooms([])
    }
  }, [building, locationData])

  useEffect(() => {
    if (inputMode) {
      if (rooms.length > 0) setRoomId(rooms[0].roomId)
      if (!rack && racks) setRack(racks[0])
      if (!row) setRow(rows[0])
      if (!column) setColumn(columns[0])
      if (!rooms) {
        setRoomId('');
      }
    } else {
      setRoomId(sessionLog.roomId)
      setRack(sessionLog.rack)
      setRow(sessionLog.rackRow)
      setColumn(sessionLog.rackColumn)
    }
  }, [building, rooms, inputMode])

  useEffect(() => {
    setError('')
  }, [rack, building, room, column, row])

  useEffect(() => {
    if (waiting) {
      setButtonText("Searching");
      setMdButtonColor("bg-yellow-300");
      setLgButtonColor("bg-yellow-300");
    } else if (showResults && found) {
      setButtonText("LED On");
      setMdButtonColor("bg-green-400");
      setLgButtonColor("bg-green-400");
      let timeout = setTimeout(() => {
        setShowResults(false);
        setFound(true);
      }, 8000);
      return () => clearTimeout(timeout);
    } else if (showResults && !found) {
      setButtonText("Not Found");
      setMdButtonColor("bg-red-500");
      setLgButtonColor("bg-red-500");
      let timeout = setTimeout(() => {
        setShowResults(false);
        setFound(true);
      }, 5000);
      return () => clearTimeout(timeout);
    } else {
      setButtonText("Find Cage");
      setMdButtonColor("bg-slate-400");
      setLgButtonColor("bg-slate-300 dark:bg-slate-500");
    }
  }, [waiting, found, showResults]);

  const turnOnLED = async () => {
    try {
      setWaiting(true);
      setLEDState(buttonState["WAITING"]);
      setShowResults(true);

      const result = await activateLight(id).unwrap();

      if (result) {
        setFound(true);
        openLocationMenu();
        setLEDState(buttonState["ON"]);
        setLightStatus("Light On");
        setSmButtonColor("bg-green-400");
      } else {
        setFound(false);
        setLEDState(buttonState["UNABLE"]);
        setLightStatus("Not found");
        setSmButtonColor("bg-slate-400");
      }
    } catch (error) {
      setFound(false);
      setLEDState(buttonState["UNABLE"]);
      setLightStatus("Error");
      setSmButtonColor("bg-red-400");
    } finally {
      setWaiting(false);
      setShow(true);
    }
  };

  useEffect(() => {
    if (!show) {
      setLEDState(buttonState["OFF"]);
    }
  }, [show])

  useEffect(() => {
    if (!inputMode) {
      setBuilding(location.building)
      setRoom(location.room)
      setRack(location.rack)
      setColumn(location.column)
      setRow(location.row)
    }
  }, [inputMode])

  return (
    <>
      {type === 'sm' ? (
        <button onClick={turnOnLED} className={`w-[30px] h-auto p-[4px] flex aspect-square border-2 rounded-md ${LEDState.classNames}`}>
          {LEDState.icon}
        </button>
      ) : type === 'md' ? (
        <p
          onClick={turnOnLED}
          className={`w-full z-[100] p-2 py-1 mb-1 rounded-t-md text-white shadow-md font-semibold text-center ${mdButtonColor}`}
        >
          {buttonText}
        </p>
      ) : (
        <div onClick={turnOnLED} className={`w-full rounded-lg flex justify-center ${lgButtonColor}`}>
          <img className="w-[42px]" src={ledIcon} />
        </div>
      )}
      <ModalWrapper isOpen={show} setIsOpen={setShow}>
        <div className="w-full rounded-md bg-slate-300 p-1 dark:bg-slate-600 text-black dark:text-white">
          <div className="z-[200] text-black dark:text-white rounded-md dark:bg-slate-800 bg-slate-200 flex flex-col items-center font-chaletNineteenSixty">
            <h2 className="mt-4 font-magBold text-2xl">Verify Cage Location</h2>
            <p
              className={`text-center inline-flex items-center justify-center py-1 px-4 my-4 whitespace-nowrap ${smButtonColor} transition-colors duration-500 ease-in-out rounded-md shadow-md`}
            >
              {lightStatus}
            </p>
            {error.length ? <p className="text-red-500">{error}</p> : null}
            {postSessionIsLoading ? <LoadingScreen /> : (
              <>
                <div className="flex flex-basis w-9/12 my-2 h-[22px]">
                  <label className="font-bold basis-2/5 pt-1">Building: </label>
                  {inputMode ? <select value={building} onChange={(e) => selectBuilding(e.target.value)} className="p-1 rounded border border-sm dark:bg-slate-600 dark:border-slate-500 border-slate-300 bg-white basis-4/5 self-start truncate w-full">
                    {buildings ? buildings.map((buildingName) => {
                      return (
                        <option key={buildingName} value={buildingName}>{buildingName}</option>
                      )
                    }) : <p className="">No buildings</p>}
                  </select> :
                    <p className="basis-4/5 self-start whitespace-nowrap overflow-hidden">
                      {location.building ? location.building : 'NONE'}
                    </p>
                  }
                </div>
                <div className="flex flex-basis w-9/12 my-2 h-[22px]">
                  <label className="font-bold basis-2/5 pt-1">Room: </label>
                  {inputMode ? rooms.length ? <select value={roomId} onChange={(e) => selectRoom(e.target.value)} className="p-1 rounded border border-sm dark:bg-slate-600 dark:border-slate-500 border-slate-300 bg-white basis-4/5 self-start whitespace-nowrap overflow-hidden">
                    {rooms.map((room) => {
                      return <option key={room.roomId} value={room.roomId}>{room.room}</option>
                    })}
                  </select> :
                    <p className="basis-4/5 flex pl-1 py-1 rounded self-start whitespace-nowrap overflow-hidden">No rooms</p>
                    :
                    <p className="basis-4/5 self-start whitespace-nowrap overflow-hidden">
                      {location.room ? location.room : 'NONE'}
                    </p>
                  }
                </div>
                <div className="flex flex-basis w-9/12 my-2 h-[22px]">
                  <label className="font-bold basis-2/5 pt-1">Rack: </label>
                  {inputMode ? <input placeholder={rack} value={rack} onChange={(e) => setRack(e.target.value)} className="w-full p-1 rounded border border-sm dark:bg-slate-600 dark:border-slate-500 border-slate-300 bg-white basis-4/5 self-start whitespace-nowrap overflow-hidden pl-1" />
                    :
                    <p className="basis-4/5 self-start whitespace-nowrap overflow-hidden">
                      {location.rack ? location.rack : 'NONE'}
                    </p>
                  }
                </div>
                <div className="flex flex-basis w-9/12 my-2 h-[22px]">
                  <label className="font-bold basis-2/5 pt-1">Column: </label>
                  {inputMode ? <select placeholder={column} value={column} onChange={(e) => setColumn(e.target.value)} className="p-1 rounded border border-sm dark:bg-slate-600 dark:border-slate-500 border-slate-300 bg-white basis-4/5 self-start whitespace-nowrap overflow-hidden">
                    {columns.map((col) => {
                      return (
                        <option key={col} value={col} className="">{col}</option>
                      )
                    })}
                  </select> :
                    <p className="basis-4/5 self-start whitespace-nowrap overflow-hidden">
                      {location.column != 'null' ? location.column : 'NONE'}
                    </p>
                  }
                </div>
                <div className="flex flex-basis w-9/12 my-2 h-[22px]">
                  <label className="font-bold basis-2/5 pt-1">Row: </label>
                  {inputMode ? <select placeholder={row} value={row} onChange={(e) => setRow(e.target.value)} className="p-1 rounded border border-sm dark:bg-slate-600 dark:border-slate-500 border-slate-300 bg-white basis-4/5 self-start whitespace-nowrap overflow-hidden">
                    {rows.map((row) => {
                      return (
                        <option key={row} value={row} className="">{row}</option>
                      )
                    })}
                  </select> :
                    <p className="basis-4/5 self-start whitespace-nowrap overflow-hidden">
                      {location.row ? location.row : 'NONE'}
                    </p>
                  }
                </div>
                <div className="flex w-10/12 justify-between py-4">
                  {inputMode ?
                    (
                      <>
                        <button onClick={() => UpdateButton()} className="rounded-md py-2 px-3 my-1 bg-sunburst text-center text-white shadow-md font-semibold">
                          Cancel
                        </button>
                        <button onClick={() => submitDeviceLocation()} className={`rounded-md py-2 px-3 my-1 disabled:bg-slate-400 disabled:opacity-55 bg-sunburst text-center text-white shadow-md font-semibold`} disabled={!rooms.length}>
                          Submit
                        </button>
                      </>
                    )
                    :
                    (
                      <>
                        <button onClick={() => UpdateButton()} className="rounded-md py-2 px-3 my-1 bg-sunburst text-center text-white shadow-md font-semibold">
                          Update
                        </button>
                        <button onClick={() => setShow(false)} className="rounded-md py-2 px-3 my-1 bg-sunburst text-center text-white shadow- font-semibold">
                          Verify
                        </button>
                      </>
                    )
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

export default LEDButton;