import CommonHeader from "./CommonHeader"
import tca from "../../images/tricorder-logo.png"

const PageNotFound = () => {
  return (
    <div>
      <CommonHeader title="404 ERROR"/>
      <img src={tca} className="absolute top-5 -left-10 w-[350px] opacity-20 -z-10"/>
      <div className="relative dark:text-white">
        <div className="absolute top-10 w-full mx-auto text-4xl font-magBook text-center">
          <div className="">404 Not Found</div>
          <div className="h-1 w-10/12 bg-sunburst"></div>
        </div>
      </div>
      <div className="mx-10 mt-[30%] dark:text-white z-10">
        There doesn't seem to be anything found on this page. Hit the back button or head to the home page to get back on track.
      </div>
    </div>
  )
}

export default PageNotFound