import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

export const penpalApiSlice = createApi({
  reducerPath: "penpalAPI",
  baseQuery: retry(fetchBaseQuery({
    baseUrl: "https://penpal-app-api.azurewebsites.net/api/",
  }), { maxRetries: 5 }),
  tagTypes: [
    "authRequests",
    "currentStaff",
    "userAuthReqForm",
    "locationsData",
    "locationAssignments",
    "adminProtocols",
    "notes",
    "clin-cases",
    "notifications",
    "protocolDevices",
    "sessions"
  ],
  keepUnusedDataFor: 1800,
  endpoints: (builder) => ({
    piGetMembersByProtocol: builder.query({
      query: (email) =>
        `getMembersByProtocol?code=6NoOxhY_nbvYgLYunu7k8kmkIerkU6KpQ0e2uJ5trDDdAzFu4x0Sdw==&email=${email}`,
      transformResponse: (response) => {
        return response.data.reduce((acc, cur) => {
          let { FirstName, LastName, MemberEmail, ProtocolID } = cur;
          if (acc[MemberEmail.toLowerCase()])
            acc[MemberEmail.toLowerCase()].Protocols.push(ProtocolID);
          else
            acc[MemberEmail.toLowerCase()] = {
              FirstName,
              LastName,
              Protocols: [ProtocolID],
              Email: MemberEmail
            };
          return acc;
        }, {});
      },
    }),
    piGetDevicesByProtocol: builder.query({
      query: (protocol) =>
        `getDevicesByProtocol?code=ZmmoLkApaoT2W5RyQnGYmo3SWpYlLFyM43tsJZA2MARjAzFucsbwiA==&protocol=${protocol}`,
      providesTags: ['protocolDevices'],
    }),
    getDevicesByInstitution: builder.query({
      query: (institution) =>
        `getDevicesByInstitution?code=_C5esji4r9n8JVDMdZVun9i73j4IVjzNs5iuNH8xcwt2AzFuqDgJcA==&institution=${institution}`,
      providesTags: ["sessions"],
    }),
    piGetLogsByDevice: builder.query({
      query: (device) =>
        `getLogsByDevice?code=38zuqff_uE6dtwgiTGywMcKf5xzwui_zjGdISa0wc88DAzFuo2ozew==&device=${device}`,
    }),
    piGetObservationsByUser: builder.query({
      query: (user) =>
        `getObservationsByUser?code=mmSfoS8c8WNcoi69hWRdwfDYjXwOb6bZE_ZXVO5q9qAGAzFus5NPDA==&user=${user}`,
    }),
    getCasesbyUser: builder.query({
      query: (user) =>
        `getCasesByUser?code=m-5PPRXm0r1pznqCvHwzZPJY142-wU-p7BRfvR2L1c6JAzFuwu6H-w==&user=${user}`,
      transformResponse: (response) => {
        let data = {};
        data["data"] = response.data.filter((value, index, self) => {
          return self.findIndex(v => v.ID === value.ID) === index;
        })
        return data;
      },
      providesTags: ["clin-cases"]
    }),
    getCasesByInstitution: builder.query({
      query: (institution) => `getClinCasesByInstitution?code=C_8EM64i4GknFF7a6n2rvltQpyTUMftp2g39qQ-QYUu_AzFuo9j3JA==&institution=${institution}`,
      transformResponse: (response) => {
        let data = {};
        data["data"] = response.data.filter((value, index, self) => {
          return self.findIndex(v => v.ID === value.ID) === index;
        })
        return data;
      },
      providesTags: ["clin-cases"]
    }),
    getObservationsByCase: builder.query({
      query: (user) =>
        `getObservationsByCase?code=QO1nGJjTRZxNCDE3aqGfNd7_4JNhn2Wspdm6SjHGyoIxAzFudfWIUA==&user=${user}`,
      transformResponse: (response) => {
        let normalizedData = [];
        for (var data in response.data) {
          let { ID, CaseID, Observer, CreatedAt, Category, Content } =
            response.data[data];
          CreatedAt = CreatedAt.replaceAll(/[TZ]/g, " ").slice(0, -5);
          normalizedData.push({
            ID,
            CaseID,
            Observer,
            CreatedAt,
            Category,
            Content,
          });
        }
        return normalizedData;
      },
    }),
/*
az webapp deployment list-publishing-profiles --name PenpalServer --resource-group appsvc_linux_centralus --subscription 03ddc7dc-3cf3-48dd-bb3e-4a685fdd5f8b --xml
*/
    getAuthenticatedUserDetails: builder.query({
      query: (email) =>
        `getAuthenticatedUserByEmail?code=tjLTk1OefAzur0AJdSXVT6fS0swNVp4VYKXaFXSWOweKAzFu_wADBw==&email=${email}`,
      providesTags: ["userAuthReqForm"],
    }),
    getUserAuthRequestByEmail: builder.query({
      query: (email) =>
        `getUserAuthRequest?code=jnTzH5_X_cX-n80HGVPhPmGPNApu-8mXw6mqRI-So2TyAzFuSPEfWw==&email=${email}`,
    }),
    getAuthRequestByInstitutionID: builder.query({
      query: (institutionID) =>
        `getAuthRequestsByInstitutionID?code=bfEd6_ajtXn-rlfdIofMPvTAR5HHkQp2VHigaHHJ4PMxAzFue-2OoQ==&institutionID=${institutionID}`,
      providesTags: ["authRequests"],
    }),
    getUsersByInstitution: builder.query({
      query: (institutionID) =>
        `getUsersByInstitution?code=bcnw0BsHrmAHLsnsULyl5PG5N396gVKJs5Una-1jr8boAzFujqXN3g==&institution=${institutionID}`,
      providesTags: ["currentStaff"],
    }),
    getLocationsByInstitution: builder.query({
      query: (institution) =>
        `getLocationsByInstitution?code=38pEJ86wEdinZ_lXDiNUBdoNFObUHTQbxYpx4q7nzp7uAzFuUTYcZw==&institution=${institution}`,
      transformResponse: (response) => {
        let normalizedData = response.data.reduce((acc, obj) => {
          const { Building, Room, Rack, RackColumn, RackRow } = obj;

          if (!acc[Building]) {
            acc[Building] = {};
          }
          if (Room && !acc[Building][Room]) {
            acc[Building][Room] = {};
            if (Rack && !acc[Building][Room][Rack]) {
              acc[Building][Room][Rack] = {};
            }
          }
          if (RackColumn && !acc[Building][Room][Rack][RackColumn]) {
            acc[Building][Room][Rack][RackColumn] = {};
          }
          if (RackColumn && !acc[Building][Room][Rack][RackColumn][RackRow]) {
            acc[Building][Room][Rack][RackColumn][RackRow] = RackRow;
          }
          return acc;
        }, {});
        return normalizedData;
      },
      providesTags: ["locationsData"],
    }),
    adminGetProtocolsByInstitution: builder.query({
      query: (institution) =>
        `getProtocolsByInstitution?code=rYS-14ALJupap3jm7Dafn4VhgxfBZnSxrceP15AOu98sAzFutyFJcw==&institution=${institution}`,
      providesTags: ["adminProtocols"],
    }),
    getLocationAssignmentsByUser: builder.query({
      query: (user) =>
        `getLocationAssignmentsByUserID?code=w3lH_geDZCX86Hw9Z4UVq1IiHdPiHfku6P_Xh2jB17z2AzFu3-cPrg==&user=${user}`,
      providesTags: ["locationAssignments"],
    }),
    getLocationByDevice: builder.query({
      query: (device) =>
        `getLocationByDevice?code=7pOPlkrDX9R_HAlmOEdx-p89Jf55l3kDtBnZ4yUjIjiYAzFuY0aqow==&device=${device}`,
      providesTags: ["locationsData"],
    }),
    getSessionByDevice: builder.query({
      query: (id) =>
        `getSessionByDevice?code=oaV1jO3BmeFlGPSyap8Egw3ibPHPPAtYKnfL4aG-H-m2AzFuKr6W_Q==&id=${id}`,
      providesTags: ["sessions"]
    }),
    postCageCardID: builder.mutation({
      query: (session) => ({
        url: `postCageCardID?code=BRRcqL-xr1V5dF_C7nJBC3XSQLVMEgQE8NYvKFwABo3KAzFuAybLxQ==`,
        method: "POST",
        body: session,
      }),
      invalidatesTags: ["sessions"],
    }),
    getNotifications: builder.query({
      query: () =>
        `getNotifications?code=yZ4n0xdh8JBQyJb_qLk8c-9FSbvvqQG7roacAGvICgV1AzFufOGd2Q==`,
      providesTags: ["notifications"]
    }),
    postDeviceLocation: builder.mutation({
      query: (location) => ({
        url: `postDeviceLocation?code=QHSsmPVBTno8JkDipvluOoYUhk1SMfonW6DofljIDKAMAzFurOecoA==`,
        method: "POST",
        body: location,
      }),
      invalidatesTags: ["locationsData"],
    }),
    postNewLocation: builder.mutation({
      query: (location) => ({
        url: `postLocation?code=V47z5w6SXHi4K0PyK9mPIK-DL5jZvwLuu670rYtD7MS_AzFuIXpNLw==`,
        method: "POST",
        body: location,
      }),
      invalidatesTags: ["locationsData"],
    }),
    piCreateObservation: builder.mutation({
      query: (observation) => ({
        url: `createObservation?code=dEeXuiC0u4QGXrNA-__Hs5afKA0TANBtmttGnrJ9g7pzAzFumTdH3A==`,
        method: "POST",
        body: observation,
      }),
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    vetCreateObservation: builder.mutation({
      query: (observation) => ({
        url: `createObservationNew?code=gvwtkQx_daurwVP3MzRjounRa4Z6AqJcGoJ8h814JgHmAzFuTNKdZA==`,
        method: "POST",
        body: observation,
      }),
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    vetCreateClinCase: builder.mutation({
      query: (observation) => ({
        url: `createClinCase?code=8J0FIjEFG6X1h-PBkdCP-WbGWQ37z2DPy7AbIiPeE2KVAzFumN24zA==`,
        method: "POST",
        body: observation,
      }),
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
      invalidatesTags: ["clin-cases"]
    }),
    userPostAuthRequest: builder.mutation({
      query: (user) => ({
        url: `postUserAuthRequest?code=5c3ekTk5ITqAkSexlV0k3c8SCqSR0gr1VARaqB0jm_66AzFu_FM-rA==`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["userAuthReqForm"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: `createUser?code=WyWlV8SCu1XnWM3q6uOK0J4aRHA-waFaIBoOgVsfqb1WAzFumBIjkw==`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["currentStaff"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    adminUpdateUserAuth: builder.mutation({
      query: (user) => ({
        url: `updateAuthorization?code=BgJ8CSqILplMyFZime3Vv4XAxAvD5FWjlj9icSsCd0lKAzFurOEOKw==`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["authRequests", "currentStaff"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    adminUpdateUser: builder.mutation({
      query: (user) => ({
        url: `updateUser?code=WEwaGG9zB9MOQJU7tWNxezLxRhEJU045ZsDi0AF4pXm5AzFur_He0w==`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["currentStaff"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    postProtocolByAdmin: builder.mutation({
      query: (protocol) => ({
        url: `postProtocolByAdmin?code=-JTh6-Z-9ABTxJy5ARgj4FnqGklLAR8Dcq-kBsHWBJNBAzFuO8Ib9Q==`,
        method: "POST",
        body: protocol,
      }),
      invalidatesTags: ["adminProtocols"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    postNotificationResolution: builder.mutation({
      query: (notification) => ({
        url: `postNotificationResolution?code=IE9pH9nL4t_fBYi62d3MDndoRVIZX_Up5l9GgnSNMVC4AzFuSR5Mwg==`,
        method: "POST",
        body: notification,
      }),
      invalidatesTags: ["notifications"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    postSessionStart: builder.mutation({
      query: (device) => ({
        url: `postSessionStart?code=_x0_EJU1MbJUItO1AYGy0CBqXcBNcLLsIsdfMCbMFPZ0AzFu_PYjsw==`,
        method: "POST",
        body: device,
      }),
      invalidatesTags: ["protocolDevices", "sessions"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    postEndSession: builder.mutation({
      query: ({ id, sessionId }) => ({
        url: `postEndSession?code=yFN8g-IXkUUti19rxh3RxCGzsseY0zS3nn1yDj_HLj78AzFutuPdzQ==`,
        method: "POST",
        body: { id, sessionId },
      }),
      invalidatesTags: ["sessions", "protocolDevices"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    postAnimalCount: builder.mutation({
      query: ({ animalCount, sessionId }) => ({
        url: `postAnimalCount?code=ukZ2ooExAIRaXZ6w1g-U6iRi582kFOgtgyzhhnoWr72wAzFusb5woA==`,
        method: "POST",
        body: { animalCount, sessionId },
      }),
      invalidatesTags: ["sessions"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) { },
    }),
    // postDeviceLocation: builder.mutation({
    //   query: (device) => ({
    //     url: ''
    //   }),
    // }),
  }),
});

export const {
  usePiGetMembersByProtocolQuery,
  usePiGetDevicesByProtocolQuery,
  useGetDevicesByInstitutionQuery,
  usePiGetLogsByDeviceQuery,
  useGetSessionByDeviceQuery,
  usePiGetObservationsByUserQuery,
  useGetCasesbyUserQuery,
  useGetCasesByInstitutionQuery,
  useGetObservationsByCaseQuery,
  usePiCreateObservationMutation,
  useVetCreateObservationMutation,
  useVetCreateClinCaseMutation,
  useGetAuthenticatedUserDetailsQuery,
  useUserPostAuthRequestMutation,
  useGetUserAuthRequestByEmailQuery,
  useGetAuthRequestByInstitutionIDQuery,
  useGetLocationsByInstitutionQuery,
  usePostDeviceLocationMutation,
  usePostNewLocationMutation,
  useGetLocationAssignmentsByUserQuery,
  useGetLocationByDeviceQuery,
  useAdminGetProtocolsByInstitutionQuery,
  useAdminUpdateUserAuthMutation,
  useGetUsersByInstitutionQuery,
  useCreateUserMutation,
  useAdminUpdateUserMutation,
  usePostProtocolByAdminMutation,
  usePostNotificationResolutionMutation,
  useGetNotificationsQuery,
  usePostCageCardIDMutation,
  usePostSessionStartMutation,
  usePostEndSessionMutation,
  usePostAnimalCountMutation,
} = penpalApiSlice;

// https://penpal-app-api.azurewebsites.net/api/postSessionStart?code=_x0_EJU1MbJUItO1AYGy0CBqXcBNcLLsIsdfMCbMFPZ0AzFu_PYjsw==
/*
 */
