import { useEffect } from "react";
import Admin from "../roles/Admin";
import CareStaff from "../roles/CareStaff";
import LabAssociate from "../roles/LabAssociate";
import PI from "../roles/PI";
import Vet from "../roles/Vet";
import { useUser } from "./UserContext";

const CheckRole = () => {
  const user = useUser();

  useEffect(() => {}, [user]);

  if (user.SelectedRole === "PI") return <PI />;
  else if (user.SelectedRole === "VET") return <Vet />;
  else if (user.SelectedRole === "PI STAFF") return <LabAssociate />;
  else if (user.SelectedRole === "CARE STAFF") return <CareStaff />;
  else if (user.SelectedRole === "ADMIN") return <Admin />;
};

export default CheckRole;
