import { useEffect, useState } from "react";
import LoadingScreen from "../components/global/LoadingScreen";
import { useUser } from "../auth/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDeleteUserAuthRequestV1Mutation, usePutUserAuthRequestV1Mutation } from "../store/penpalServerAPI";

const CreateAccount = ({ isSuccess = false, isLoading = false }) => {
  const user = useUser();
  const [email, setEmail] = useState(user.Email.toLowerCase() || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState('');
  const [fax, setFax] = useState('');
  const [institutionId, setInstitutionId] = useState("");
  const [roles, setRoles] = useState([]);
  const [pi, setPi] = useState('');
  const [vet, setVet] = useState('');
  const [admin, setAdmin] = useState('');
  const [vetStaff, setVetStaff] = useState('');
  const [careStaff, setCareStaff] = useState('');
  const [errors, setErrors] = useState([]);

  const [
    putUserAuthRequest,
    {
      data: putReqData,
      isLoading: putReqIsLoading,
      isSuccess: putReqIsSuccess,
      isError: putReqIsError,
      error: putReqError
    }
  ] = usePutUserAuthRequestV1Mutation();

  const [
    deleteUserAuthRequest,
    {
      data: deleteReqData,
      isLoading: deleteReqIsLoading,
      isSuccess: deleteReqIsSuccess,
      isError: deleteReqIsError,
      error: deleteReqError
    }
  ] = useDeleteUserAuthRequestV1Mutation();

  useEffect(() => {
    setRoles([pi, vet, admin, vetStaff, careStaff])
  }, [pi, vet, admin, vetStaff, careStaff]);

  useEffect(() => {
    if (user.Email) setEmail(user.Email.toLowerCase());
  }, [user]);

  useEffect(() => {
    setErrors([]);
  }, [email, firstName, lastName, institutionId, roles])

  useEffect(()=>{
  }, [isSuccess])

  function checkErrors(obj) {
    if (!obj.email) {
      setErrors([...errors, "Email Required"])
      return false;
    }
    if (!obj.firstName) {
      setErrors([...errors, "First Name Required"])
      return false;
    }
    if (!obj.lastName) {
      setErrors([...errors, "Last Name Required"])
      return false;
    }
    if (!obj.institutionId) {
      setErrors([...errors, "Institution Required"])
      return false;
    }
    if (!obj.roles) {
      setErrors([...errors, "Role(s) Required"])
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    const user = {
      email: email.toLowerCase(),
      firstName,
      lastName,
      phone,
      fax,
      institutionId,
      roles: roles.filter(role => role.length).join(','),
    };
    checkErrors(user)
    if (!errors.length) {
      await putUserAuthRequest(user);
    } else {
      console.log("Required fields missing.")
      console.log(putReqError)
    }
  };

  function updatePi() {
    if (pi) setPi('')
    else setPi('PI')
  }

  function updateVet() {
    if (vet) setVet('')
    else setVet('VET')
  }

  function updateAdmin() {
    if (admin) setAdmin('')
    else setAdmin('ADMIN')
  }

  function updateVetStaff() {
    if (vetStaff) setVetStaff('')
    else setVetStaff('VETSTAFF')
  }

  function updateCareStaff() {
    if (careStaff) setCareStaff('')
    else setCareStaff('CARESTAFF')
  }

  return (
    <div className="w-screen p-4 flex flex-col items-center justify-center">
      {isLoading ? <LoadingScreen /> : isSuccess ? (
        <div className="w-11/12 max-w-[500px] py-6 shadow-lg rounded-xl px-1 flex flex-col justify-center items-center my-4 font-chaletNineteenSixty bg-stone-50">
          <div className="flex w-11/12 mb-4">
            <p className="text-lg font-bold dark:text-white">
              Welcome back,&nbsp;
            </p>
            <p className="text-lg">
              {user.FirstName}.
            </p>
          </div>
          <p className="w-11/12 text-justify dark:text-white">
            You've successfully submitted a request for authorization. Your
            administrator hasn't authorized you just yet. If any information below is inaccurate, please update and reach out to your administrator.
          </p>
        </div>
      ) : putReqIsLoading ? <LoadingScreen /> : (
        <>
          <div className="w-full flex justify-center">
            <p className="text-lg font-chaletNineteenSixty dark:text-white">Create your User Profile</p>
          </div>
          {errors ? (
            <div>
              {errors.map((msg) => {
                return (
                  <p className="text-red-600">{msg}</p>
                )
              })}
            </div>
          ) : null}
          <div className="w-11/12 max-w-[500px] min-h-[320px] bg-stone-50 shadow-lg rounded-xl px-2 flex flex-col mb-4 font-chaletNineteenSixty dark:bg-gray-700">
            <div className="">
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>First Name</label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Phone</label>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.replace(/[^0-9.]/g, ''))}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Fax</label>
                <input
                  type=""
                  value={fax}
                  onChange={(e) => setFax(e.target.value.replace(/[^0-9.]/g, ''))}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                />
              </div>
              <div className="flex justify-between my-6">
                <label className={`dark:text-white`}>Institution</label>
                <select
                  value={institutionId}
                  onChange={(e) => setInstitutionId(e.target.value)}
                  className="w-[205px] md:max-w-[210px] relative rounded-md shadow-md border focus:outline-none focus:border-sunburst pl-2 h-8"
                >
                  <option value={""}>Select one...</option>
                  <option value={"TRICORDER"}>Tricorder</option>
                  <option value={"UAB"}>UAB</option>
                </select>
              </div>
              <div className="flex justify-between my-6">
                <p className="whitespace-nowrap dark:text-white">Select Roles</p>
                <div className="w-[205px] md:max-w-[210px] flex flex-wrap gap-2">
                  <button
                    onClick={updatePi}
                    className={`border rounded px-3 py-1 ${pi.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}
                  >PI</button>
                  <button
                    onClick={updateVet}
                    className={`border rounded px-3 py-1 ${vet.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Veterinarian</button>
                  <button
                    onClick={updateAdmin}
                    className={`border rounded px-3 py-1 ${admin.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Admin</button>
                  <button
                    onClick={updateVetStaff}
                    className={`border rounded px-3 py-1 ${vetStaff.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Vet Staff</button>
                  <button
                    onClick={updateCareStaff}
                    className={`border rounded px-3 py-1 ${careStaff.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Care Staff</button>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center pb-2">
              <button
                className="py-2 w-6/12 my-4 shadow-md bg-sunburst rounded-lg flex justify-center hover:cursor-pointer text-white text-lg"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateAccount;
