import { createContext, useContext, useState } from "react";

export const UserContext = createContext({
  UserID: "",
  Email: "",
  FirstName: "",
  MiddleName: "",
  LastName: "",
  SelectedRole: "",
  Roles: [],
  Authorized: false
});
export const SetUserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const useSetUser = () => {
  return useContext(SetUserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    UserID: "",
    Email: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SelectedRole: "",
    Roles: [],
    Authorized: false
  });

  function updateUser(obj) {
    setUser(obj);
  }

  return (
    <UserContext.Provider value={user}>
      <SetUserContext.Provider value={updateUser}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
};
