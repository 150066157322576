import { Link, useNavigate, useParams } from "react-router-dom";
import NotificationsMenu from "../modals/NotificationsModal";
import UserMenu from "./Sidebar";
import { useEffect, useState } from "react";
import { useUser } from "../../auth/UserContext";
import arrow from "../../icons/arrow.png"
import { usePostPageTrackerV1Mutation } from "../../store/penpalServerAPI";
import SettingsCog from "./SettingsCog";
import HeaderMenu from "./HeaderMenu";

const CommonHeader = ({ title = "", active = true }) => {
  const { sessionId, id, naturalId } = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const [prev, setPrev] = useState('');
  const [atHome, setAtHome] = useState(window.location.pathname === '/' ? true : false);
  const [path, setPath] = useState(window.location.pathname);
  const [postPageTrackerV2Mutation, { data, error }] = usePostPageTrackerV1Mutation();

  useEffect(() => {
    if (user.SelectedRole === 'PI') return;
    else {
      if (window.location.pathname.split('/').length <= 1) {
        setPrev('')
      }
      if (window.location.pathname.split('/').length <= 2) {
        setPrev('/');
      } else {
        setPrev(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
      }
    }
  }, [title])


  const pageTrackerAPI = async () => {
    //Regular expresions to match GUIDs, MacAddresses and NaturalID's
    const guidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
    const macPattern = /[0-9a-fA-F]{12}/g;
    const natIdPattern = /[0-9]{2}/g;

    const anonymizePath = (path) => {
      return path.replace(guidPattern, 'GUID').replace(macPattern, 'MAC').replace(natIdPattern, 'NID');
    }

    // Checking to see if in the development environment, if so, do not call API to avoid duplicate entries in DB. 
    // This is because we are using React.StrictMode, thus it double renders everything in attempt to check for errors and other issues.
    if (process.env.NODE_ENV !== 'development') {
      const pageData = {
        userId: user.ID,
        prettyURL: user.SelectedRole.concat(anonymizePath(path)),
        rawURL: user.SelectedRole.concat(path)
      };

      try {
        let res = await postPageTrackerV2Mutation(pageData);
        console.log("PageTracker Response: ", res);
      }
      catch {
        console.error("PageTracker API Error: ", error)
      }
    }
  }

  useEffect(() => {
    // Change the color above the safe area to sunburst for mobile devices.
    document.querySelector("meta[name='theme-color']").setAttribute("content", '#f8a832');

    setPath(window.location.pathname);
    pageTrackerAPI();
  }, []);

  const handlePiClick = () => {
    if (title === 'Notifications') {
      navigate(-1)
      return;
    }
    if (title === 'Clinical Case') {
      navigate(-1)
      return;
    }
    if (title === 'Update Session') {
      navigate(-1)
      return;
    }
    if (window.location.pathname.split('/')[1] === 'clinical-cases' && window.location.pathname.split('/').length === 3) {
      navigate(-1)
    }
    if (window.location.pathname.split('/').length < 2) return;
    if (active === false) navigate(`/penpals`);
    if (window.location.pathname.split('/').length === 2) {
      navigate('/')
    } else if (window.location.pathname.split('/').length === 3) {
      let pathArray = window.location.pathname.split('/');
      if (pathArray[1] === 'penpals') navigate('/')
      else if (pathArray[1] === 'activate') navigate('/penpals')
      else navigate(`/${pathArray[1]}`)
    } else if (window.location.pathname.includes('notes') && window.location.pathname.slice(-3) === 'new') {
      navigate(`/penpals/${id}/notes/${sessionId}`)
    } else if (window.location.pathname.includes('notes')) navigate(`/penpals/${id}`)
    else if (window.location.pathname.includes(`clinical-cases/new/${sessionId}/${naturalId}`)) {
      navigate(-1)
    } else if (window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'new-observation') {
      navigate(`/clinical-cases/${id}`)
    }
  }

  return (
    <div className="sticky z-[250] flex flex-col w-screen bg-sunburst items-center">
      <div className="w-full flex justify-between items-center py-2">
        <div className="flex items-center pl-4">
          <UserMenu />
        </div>
        <div className="flex">
          <div className="flex items-center gap-2">
            {user.Authorized ? (
              <NotificationsMenu />
            ) : null}
          </div>
          <div className="mr-3 mt-2">
            <SettingsCog />
          </div>
        </div>
      </div>
      <HeaderMenu />
      {/* {title !== "" ? (
        <div className="w-full border-slate-300 dark:border-black border-y-2">
          <div className="flex flex-row gap-2 text-left py-1 pl-6 w-full text-2xl font-magBold shadow-md bg-slate-200 dark:bg-slate-700 text-black dark:text-white">
          <p className="flex w-10/12 text-center">{title}</p>
          </div>
        </div>
      ) : null} */}
    </div>
  );
};

export default CommonHeader;
