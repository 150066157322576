import { useEffect, useState } from "react";
import penPalLogo from "../images/penPalText.png";
import penPalLogoDark from "../images/penPalTextInverted.png";
import { Link } from "react-router-dom";
import { useSetUser } from "../auth/UserContext";
import hidden from "../icons/hidden.png";
import visible from "../icons/visible.png";
import LoadingScreen from "../components/global/LoadingScreen";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
  const [show, setShow] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [logoType, setLogoType] = useState(penPalLogo);
  const [pwText, setPwText] = useState("password");
  const [pwImg, setPwImg] = useState(hidden);
  const updateUser = useSetUser();
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  const toggleViewPassword = () => {
    if (pwText === "password") {
      setPwText("text");
      setPwImg(visible);
    } else {
      setPwText("password");
      setPwImg(hidden);
    }
  };

  useEffect(() => {
    setShowLogo(true);
    setLogoType(window.matchMedia('(prefers-color-scheme: dark)').matches ? penPalLogoDark : penPalLogo)
    new Promise(() => { setTimeout(800); setShow(true) });
  }, []);

  const loginWithAuth0 = () => {
    loginWithRedirect();
  }

  const handleSignUp = () => {
    loginWithRedirect({ authorizationParams: { screen_hint: "signup" } })
  }

  return (
    <div className="w-screen p-4 mb-24 mt-16 flex flex-col items-center justify-center">
      <div className="w-11/12 max-w-[500px] flex flex-col justify-center">
        <img
          src={logoType}
          alt="penpal logo"
          className={`w-full ${showLogo ? "opacity-1" : "opacity-0"
            } transition-all ease-in-out duration-500 mb-5`}
        />
      </div>
      <div className="w-11/12 max-w-[500px] -mt-[22px] mb-[44px] flex flex-col justify-center shadow-lg dark:bg-slate-700 bg-stone-50 rounded-lg py-4 font-chaletNineteenSixty">
        <div
          className={`${show ? "opacity-1" : "opacity-0"
            } text-lg flex flex-col items-center rounded-t-xl transition-all ease-in-out duration-500`}
        >
          <button
            className="py-2 w-8/12 my-4 shadow-md dark:bg-slate-500 dark:text-white bg-slate-200 rounded flex justify-center hover:cursor-pointer"
            onClick={loginWithAuth0}
          >
            Login
          </button>
        </div>
        <div
          className={`${show ? "opacity-1" : "opacity-0"
            } text-lg flex flex-col items-center transition-all ease-in-out duration-500`}
        >
          <div className="flex w-10/12 items-center justify-center">
            <div className="h-[1px] dark:bg-slate-500 bg-gray-300 w-5/12"></div>
            <p className="mx-3 pb-1 dark:text-white">or</p>
            <div className="h-[1px] dark:bg-slate-500 bg-gray-300 w-5/12"></div>
          </div>
          <button
            onClick={handleSignUp}
            className={`py-2 w-8/12 my-4 shadow-md bg-sunburst rounded flex justify-center hover:cursor-pointer`}
          >
            <p className="text-white">Sign Up</p>
          </button>
        </div>
      </div>
      <div className="absolute flex flex-col justify-center items-center bottom-0 h-20 w-screen text-sm font-chaletSixty dark:text-white">
        <p>&copy;Copyright 2023 Tricorder Array Technologies, LLC</p>
        <p>All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Login;
