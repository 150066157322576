import { createContext, useContext, useState } from "react";

const getTokenContext = () => {
  if (localStorage.getItem("authToken") === null)
    localStorage.setItem("authToken", "");
  return localStorage.getItem("authToken");
};

const changeTokenContext = (str) => {
  localStorage.setItem("authToken", str);
};

export const TokenContext = createContext();
export const UpdateTokenContext = createContext();

export const useTokenContext = () => {
  return useContext(TokenContext);
};

export const useUpdateTokenContext = () => {
  return useContext(UpdateTokenContext)
;}

export const TokenContextProvider = ({ children }) => {
  const [token, setToken] = useState(getTokenContext());

  const updateToken = (x) => {
    changeTokenContext(x)
    setToken(x)
  }

  return (
    <TokenContext.Provider value={token}>
      <UpdateTokenContext.Provider value={updateToken}>
        {children}
      </UpdateTokenContext.Provider>
    </TokenContext.Provider>
  );
};