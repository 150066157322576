import { useState } from "react";
import { useDarkMode } from "../components/context/ThemeContext";

const BugForm = () => {
  const [message, setMessage] = useState("");
  const darkMode = useDarkMode();

  const submitBug = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className={`min-h-[70vh] w-screen z-20 flex flex-col items-center ${
        darkMode ? "bg-zinc-900" : "bg-white"
      }`}
    >
      <h2 className="py-6 text-3xl text-center text-sunburst font-magBold">
        Bug Report
      </h2>
      <form
        onSubmit={submitBug}
        className="w-10/12 flex flex-col items-center py-4 px-4 bg-slate-200 rounded-lg border-2 border-black"
      >
        <p className="w-9/12 text-justify">Please describe the issue you encountered with surrounding context.</p>
        <textarea
          className="w-10/12 min-h-[120px] border border-black rounded p-2 my-4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          className="border-2 border-slate-600 rounded-md px-3 py-1 bg-sunburst text-center font-magBold text-xl"
          type="submit"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default BugForm;
