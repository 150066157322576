import { createContext, useContext, useState } from "react";

export const RedirectUser = createContext();
export const UpdateRedirectUser = createContext();

export const useRedirectUser = () => {
  return useContext(RedirectUser);
};
export const useUpdateRedirect = () => {
  return useContext(UpdateRedirectUser);
};

export const RedirectUserProvider = ({ children }) => {
  const [redirectUserState, setRedirectUserState] = useState('');

  const updateRedirect = (url) => {
    setRedirectUserState(url)
  }

  return (
    <RedirectUser.Provider value={redirectUserState}>
      <UpdateRedirectUser.Provider value={updateRedirect}>
        {children}
      </UpdateRedirectUser.Provider>
    </RedirectUser.Provider>
  );
};
