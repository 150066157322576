import { BrowserRouter, Route, Routes } from "react-router-dom";
import UnauthorizedHome from "../pages/Unauthorized/UnauthorizedHome";
import PageNotFound from "../components/global/PageNotFound";

const Unauthorized = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<UnauthorizedHome />} />
        <Route path="/*" element={<PageNotFound/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default Unauthorized;