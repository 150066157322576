import { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ledIcon from '../../icons/light.png'
import ModalWrapper from "../modals/ModalWrapper";
import FormatDateTime from "../global/FormatDateTime";
import LEDButton from "../buttons/LEDButton"
import { useUser } from "../../auth/UserContext";
import { useSelectedNotif, useUpdateSelectedNotif } from "../context/NotifContext";

const NotificationItem = ({ notif, colorFromType, atNotificationsPage }) => {
  const user = useUser();
  const lDiv = useRef();
  const snDiv = useRef();
  const lElement = useRef();
  const snElement = useRef();
  const [open, setOpen] = useState(false);
  const [ledBg, setLedBg] = useState('bg-slate-200');
  const [errors, setErrors] = useState({ led: "" });
  const [updatedLocation, setUpdatedLocation] = useState(false);
  const updateSelectedNotif = useUpdateSelectedNotif();
  const selectedNotif = useSelectedNotif();

  useEffect(() => {
    if (notif.macAddress === notif.macAddress.toLowerCase()) return
    else navigate(`/penpals/${notif.macAddress.toLowerCase()}`)
    if (updatedLocation) {
      getSessionLogs({ sessionId: notif.sessionId, amount: 1 });
      setUpdatedLocation(false);
    }
  }, [updatedLocation]);

  useEffect(() => {
    if (!open) {
      setLedBg('bg-slate-300 dark:bg-slate-500 dark:text-white')
      setErrors({ led: "" })
    } else {
      updateSelectedNotif(notif.id)
    }
  }, [open])

  useEffect(() => {
    const resetAnimation = (element) => {
      if (element) {
        element.style.animationDuration = '';
        element.style.paddingRight = '';
        element.classList.remove('animate-marquee');
      }
    };

    if (notif?.sessionName?.length > 18 && snDiv.current && snElement.current) {
      const nameWidth = snElement.current.scrollWidth;
      const containerWidth = snDiv.current.offsetWidth;
      const duration = (nameWidth / containerWidth) * 10;
      snElement.current.style.animationDuration = `${duration}s`;
      if (notif.sessionName?.length > 20) {
        snElement.current.style.paddingRight = `${containerWidth * 2}px`;
      } else {
        snElement.current.style.paddingRight = `${containerWidth}px`;
      }
      snElement.current.classList.add('animate-marquee');
    } else {
      resetAnimation(snElement.current);
    }

    if (notif?.location?.length > 18 && lDiv.current && lElement.current) {
      const nameWidth = lElement.current.scrollWidth;
      const containerWidth = lDiv.current.offsetWidth;
      const duration = (nameWidth / containerWidth) * 10;
      lElement.current.style.animationDuration = `${duration}s`;
      lElement.current.style.paddingRight = `${containerWidth * 3}px`;
      lElement.current.style.paddingLeft = `3px`;
      lElement.current.classList.add('animate-marquee');
    } else {
      resetAnimation(lElement.current);
    }
  }, [notif, open]);

  const selectNotification = () => {
    setOpen(true)
    updateSelectedNotif(notif.id)
  }

  return (
    <>
      {atNotificationsPage ? (
        <button
          key={notif.id}
          className="w-full flex justify-center mb-1 h-[75px]"
          onClick={() => selectNotification(true)}
        >
          <div
            className={`w-full ${colorFromType(notif.level)} flex flex-col items-between border dark:border-slate-600 pl-2 rounded-lg min-h-[56px] overflow-hidden`}
          >
            <div className="flex justify-between">
              <span className={`pl-1 text-xl whitespace-nowrap font-semibold`}>
                {notif.name === "Unsafe Temperature" ? "Unsafe Temp" : notif.name}
              </span>
              <span className="text-sm bg-gray-400 dark:bg-gray-800 text-white whitespace-nowrap rounded-lg flex justify-center items-center px-1 max-w-[130px] min-w-[130px]">
                <FormatDateTime dateTime={notif.timestamp} />
              </span>
            </div>
            <div className="flex w-full justify-between">
              <span className="pl-1">
                Penpal: {notif.deviceNaturalId}
              </span>
              <span className="pr-5">
                Session: {notif.sessionNaturalId}
              </span>
            </div>
            <div className="flex flex-col items-start justify-between mt-1 text-xs pl-1">
              <span className="text-xs font-semibold">{notif.location}</span>
            </div>
          </div>
        </button>
      ) : (
        <Link
          key={notif.id}
          className="w-full flex justify-center mb-1"
          to={`/notifications/${notif.id}`}
        >
          <div
            className={`relative w-full bg-slate-300 dark:bg-slate-700 h-[75px] flex flex-col items-between border dark:border-slate-600 dark:text-white pl-2 rounded-lg min-h-[56px] overflow-hidden`}
          >
            <div
              className={`position: absolute left-0 rounded-l-lg w-[10px] h-[74px] ${colorFromType(notif.level)}`}
            ></div>
            <div className="flex justify-between">
              <span className={`pl-1 text-xl whitespace-nowrap`}>
                {notif.name === "Unsafe Temperature" ? "Unsafe Temp" : notif.name}
              </span>
              <div className="text-sm bg-gray-400 dark:bg-gray-800 text-white whitespace-nowrap rounded-lg flex justify-center items-center px-1 max-w-[130px] min-w-[130px]">
                <FormatDateTime dateTime={notif.timestamp} />
              </div>
            </div>
            <div className="flex w-full justify-between">
              <span className="pl-1">Penpal: {notif.deviceNaturalId}</span>
              <span className="pr-5">Session: {notif.sessionNaturalId}</span>
            </div>
            <div className="flex flex-col items-start justify-between mt-1 text-xs pl-1">
              <span className="text-xs">{notif.location}</span>
            </div>
          </div>
        </Link>
      )}
      {open && (
        <ModalWrapper isOpen={open} setIsOpen={setOpen}>
        <div className="flex flex-col items-center bg-slate-300 dark:bg-slate-600 rounded-md p-1">
          <div className="flex flex-col items-center min-h-[400px] max-w-[500px] bg-slate-200 dark:bg-slate-800 rounded-md z-[871]">
            <div className="grid grid-cols-6 grid-rows-3 justify-center items-center w-11/12 h-[120px] px-2 border-b border-slate-300 dark:border-slate-600">
              <div className="row-start-1 col-start-1 col-span-3 text-lg">
                <p className="text-lg dark:text-white">Cage Name:&nbsp;</p>
              </div>
              <div ref={snDiv} className="row-start-1 col-start-4 col-span-3 overflow-hidden text-right">
                <p ref={snElement} className="text-lg dark:text-white whitespace-nowrap">
                  {notif.sessionName ? notif.sessionName : <i className="opacity-60">{`Unnamed ${notif.sessionNaturalId}`}</i>}
                </p>
              </div>
              <div className="row-start-2 col-start-1 col-span-3 text-lg">
                <p className="text-black dark:text-white ">Penpal ID:&nbsp;</p>
              </div>
              <div className="row-start-2 col-start-5 col-span-2 text-right">
                <p className="font-bold dark:text-white">{notif.deviceNaturalId}</p>
              </div>
              <div className="row-start-3 col-start-1 col-span-2 text-lg">
                <p className="text-black dark:text-white ">Location:&nbsp;</p>
              </div>
              <div ref={lDiv} className="row-start-3 col-start-3 col-span-4 overflow-hidden">
                <p ref={lElement} className="font-bold dark:text-white whitespace-nowrap">
                  {notif.location}
                </p>
              </div>
            </div>
            <div className={`w-10/12 p-2 my-4 rounded-lg border ${colorFromType(notif.level)}`}>
              <div className="w-full flex flex-col">
                <div className="flex my-1 text-lg">
                  <p className="basis-5/12">Code:&nbsp;</p>
                  <p className="w-full text-right font-bold">{notif.name}</p>
                </div>
                <div className="flex my-1 text-lg">
                  <p className="basis-5/12">Created:&nbsp;</p>
                  <div className="w-full text-right">
                    <FormatDateTime dateTime={notif.timestamp} />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full items-center my-6">
              {errors.led.length ? <p className="text-red-500">Error Locating Device</p> : null}
              <div className="w-4/5 border border-slate-400 rounded-lg flex justify-center my-2">
                <LEDButton id={notif.macAddress} location={notif.locationData} sessionLog={notif.sessionLogs} setUpdatedLocation={setUpdatedLocation} type={'lg'} />
              </div>
              <Link to={`/penpals/${notif.macAddress}`} className="w-4/5 py-2 border border-slate-400 rounded-lg flex justify-center bg-slate-300 dark:bg-slate-500 dark:text-white my-2">
                <p className="text-lg">View Cage</p>
              </Link>
              {notif.typeId === '0add50ab-059f-4b44-8318-aeb384833419' && (
                <Link to={`/clinical-cases/${notif.caseId}`} className="w-4/5 py-2 border border-slate-400 rounded-lg flex justify-center bg-slate-300 dark:bg-slate-500 dark:text-white my-2">
                  <p className="text-lg">View Clin Case</p>
                </Link>
              )}
              {user?.SelectedRole === 'VET' && notif.name === 'Clinical Case' && (
                <Link to={`/vet-dashboard/${notif.deviceId}/${notif.sessionId}`} className="w-4/5 py-2 border border-slate-500 rounded-lg flex justify-center bg-slate-300 dark:bg-slate-500 dark:text-white my-2">
                  <p className="text-lg">Cage Data</p>
                </Link>
              )}
              {(notif.typeId === '1c3fca50-9121-4e73-a35b-78f119e1ca42' ||
                notif.typeId === '281821dd-ed50-4085-aab1-84c9062f39e6' ||
                notif.typeId === '3eb600d4-be8d-4485-80de-4eb7082d0e5f' ||
                notif.typeId === 'd89e1d5c-0ac1-47a0-ad1d-9a00529a8ba4') && (
                <Link to={`/clinical-cases/new/${notif.sessionId}/${notif.deviceNaturalId}`} className="w-4/5 py-2 border border-slate-500 rounded-lg flex justify-center bg-slate-300 dark:bg-slate-500 dark:text-white my-2">
                  <p className="text-lg">Resolve</p>
                </Link>
              )}
            </div>
          </div>
        </div>
      </ModalWrapper>
      )}
    </>
  );
};

export default NotificationItem;
