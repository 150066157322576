import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import penpalLogo from "../images/penPalText.png"
import { useUserPostAuthRequestMutation } from "../store/penpalApiSlice";
import LoadingScreen from "../components/global/LoadingScreen";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [institution, setInstitution] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const [postAuthRequest, { isLoading, isSuccess, isError, error, isUninitialized }] = useUserPostAuthRequestMutation();

  let emailFormat = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  useEffect(() => {
    if (!errors) return;
    if (errors.includes('Invalid Email') && email.length && email.match(emailFormat)) {
      setErrors((x) => x.filter((a) => a !== 'Invalid Email'))
    }
    if (errors.includes('First Name Required') && firstName.length > 1) {
      setErrors((x) => x.filter((a) => a !== 'First Name Required'))
    }
    if (errors.includes('Last Name Required') && lastName.length > 1) {
      setErrors((x) => x.filter((a) => a !== 'Last Name Required'))
    }
    if (errors.includes('Institution Required') && institution.length > 1) {
      setErrors((x) => x.filter((a) => a !== 'Institution Required'))
    }
    if (errors.includes('Role Required') && role.length) {
      setErrors((x) => x.filter((a) => a !== 'Role Required'))
    }
  }, [email, firstName, lastName, institution, role])

  useEffect(() => {
    if (!errors.length) setDisabled(false)
  }, [errors])

  const createUser = async () => {
    let myErrors = checkErrors();
    if (myErrors.length) {
      setErrors(myErrors);
      setDisabled(true);
      return;
    } else {
      let newUser = {
        email,
        firstName,
        lastName,
        role,
        institutionId: institution
      };
      let res = await postAuthRequest(newUser).unwrap();
      setUser(res.data[0])
    }
  };

  const checkErrors = () => {
    let formErrors = [];
    if (!email || !email.match(emailFormat)) {
      formErrors.push('Invalid Email')
    }
    if (!firstName) formErrors.push('First Name Required');
    if (!lastName) formErrors.push('Last Name Required');
    if (!institution) formErrors.push('Institution Required');
    if (!role) formErrors.push('Role Required');
    return formErrors;
  };

  return (
    <>
      <div className="w-screen p-4 mb-24 mt-16 flex flex-col items-center justify-center">
        <div className="w-11/12 max-w-[500px] flex flex-col justify-center">
          <img
            src={penpalLogo}
            alt="penpal logo"
            className={`w-full mb-5`}
          />
        </div>
        {isSuccess ? isLoading ? (
          <LoadingScreen />
        ) : (
          <>
          <div className="w-full -mt-[22px] mb-[44px] flex flex-col items-center shadow-lg bg-stone-50 rounded-lg py-4 font-chaletNineteenSixty text-lg max-w-[440px]">
            <div className="w-10/12 flex flex-col items-center">
              <p className="text-3xl mb-6">Success!</p>
              <p className="text-justify font-chaletNineteenSixty">Your request has been received. Next, contact your {user.InstitutionID} administrator for authorization.</p>
            </div>
          </div>
            <Link to="/" className="px-5 py-2 border rounded-md border-black shadow-md">Back</Link>
          </>
        ) : (
          <div className="w-full -mt-[22px] mb-[44px] flex flex-col items-center shadow-lg bg-stone-50 rounded-lg py-4 font-chaletNineteenSixty text-lg max-w-[440px]">
            <div className="w-11/12 flex flex-col items-center">
              <div className="flex text-sm text-stone-500 my-2">
                <p className="mr-2">Already have an account?</p>
                <Link to={'/'} className="underline text-stone-700">Log in</Link>
              </div>
              <div className="w-full flex justify-between my-3">
                <label className="whitespace-nowrap basis-4/12">Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  className="rounded border border-black focus:border-sunburst pl-2 basis-7/12"
                />
              </div>
              <div className="w-full flex justify-between my-3">
                <label className="whitespace-nowrap basis-4/12">
                  First Name
                </label>
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="rounded border border-black focus:border-sunburst pl-2 basis-7/12"
                />
              </div>
              <div className="w-full flex justify-between my-3">
                <label className="whitespace-nowrap basis-4/12">Last Name</label>
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="rounded border border-black focus:border-sunburst pl-2 basis-7/12"
                />
              </div>
              <div className="w-full flex justify-between my-3">
                <label className="whitespace-nowrap basis-4/12">
                  Institution
                </label>
                <select
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                  className="rounded border border-black focus:border-sunburst pl-2 basis-7/12 w-7/12"
                >
                  <option value={""}>--Select One--</option>
                  <option value={"UAB"}>UAB</option>
                  <option value={"TRICORDER"}>Tricorder</option>
                </select>
              </div>
              <div className="w-full flex justify-between my-3">
                <label className="whitespace-nowrap basis-4/12">
                  Role
                </label>
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="rounded border border-black focus:border-sunburst pl-2 basis-7/12 w-7/12"
                >
                  <option value={""}>--Select One--</option>
                  <option value={"Primary Investigator"}>Primary Investigator</option>
                  <option value={"Lab Associate"}>Lab Associate</option>
                  <option value={"Vet"}>Veterinarian</option>
                  <option value={"Care Staff"}>Care Staff</option>
                </select>
              </div>
              {errors.length ? (
                <div className="w-full flex flex-col items-center">
                  {errors.map(error => {
                    return (
                      <p key={error} className="text-red-400">{error}</p>
                    )
                  })}
                </div>
              ) : null}
              <div className="flex mt-7 mb-5 text-white">
                <button
                  onClick={createUser}
                  disabled={disabled}
                  className="rounded-md px-3 py-1 bg-sunburst text-center text-xl disabled:opacity-50"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="absolute flex flex-col justify-center items-center bottom-0 h-20 w-screen text-sm font-chaletSixty">
          <p>&copy;Copyright 2023 Tricorder Array Technologies, LLC</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Signup;