const NoLightSVG = ({className, classBulb, classCross}) => {
  return(
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 10 15"
      version="1.1"
      id="svg5"
      style={{ width: '100%', height: 'auto' }}>
      <path
        className={classBulb}
        id="rect577"
        d="M 5.000259,4.7e-5 A 4.9999995,4.9999995 0 0 0 2.550279,0.643419 L 5.000259,3.093398 7.451272,0.642385 A 4.9999995,4.9999995 0 0 0 5.000259,4.7e-5 Z M 9.357615,2.549245 6.906602,5.000259 9.356582,7.450238 A 4.9999995,4.9999995 0 0 0 9.999953,5.000259 4.9999995,4.9999995 0 0 0 9.357615,2.549245 Z M 0.643419,2.550279 A 4.9999995,4.9999995 0 0 0 4.7e-5,5.000259 4.9999995,4.9999995 0 0 0 0.643419,7.450238 L 3.093398,5.000259 Z m 4.35684,4.35684 -2.44998,2.449463 A 4.9999995,4.9999995 0 0 0 2.719778,9.443915 V 11.64068 H 7.280223 V 9.443915 A 4.9999995,4.9999995 0 0 0 7.449721,9.356582 Z m -2.280481,5.496305 v 1.56993 h 1.067635 a 1.2499998,1.2500048 0 0 0 1.212329,0.949813 1.2499998,1.2500048 0 0 0 1.212846,-0.949813 h 1.067635 v -1.56993 z" />
      <path
        className={classCross}
        id="path1494"
        d="M 0,0 V 1.271158 L 3.728842,4.999999 0,8.728842 V 10 H 1.271158 L 5.000001,6.271158 8.728842,10 H 10 V 8.728842 L 6.271159,4.999999 10,1.271158 V 0 H 8.728842 L 5.000001,3.728841 1.271158,0 Z" />
    </svg>
  );
}

export default NoLightSVG;