import { createContext, useContext, useState } from "react";

const getSelectedNotif = () => {
  if (sessionStorage.getItem("selectedNotif") === null)
  sessionStorage.setItem("selectedNotif", "");
  return sessionStorage.getItem("selectedNotif");
};

const changeSelectedNotif = (pref) => {
  sessionStorage.setItem("selectedNotif", pref);
};

export const SelectedNotifContext = createContext(getSelectedNotif());
export const UpdateSelectedNotifContext = createContext();

export const useSelectedNotif = () => {
  return useContext(SelectedNotifContext);
};
export const useUpdateSelectedNotif = () => {
  return useContext(UpdateSelectedNotifContext);
};

export const SelectedNotifProvider = ({ children }) => {
  const [selectedNotif, setSelectedNotif] = useState(getSelectedNotif());

  const updateSelectedNotif = (pref) => {
    changeSelectedNotif(pref);
    setSelectedNotif(pref);
  };

  return (
    <SelectedNotifContext.Provider value={selectedNotif}>
      <UpdateSelectedNotifContext.Provider value={updateSelectedNotif}>
        {children}
      </UpdateSelectedNotifContext.Provider>
    </SelectedNotifContext.Provider>
  );
};
