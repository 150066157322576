import { createContext, useContext, useState } from "react";

const getTempPref = () => {
  if (localStorage.getItem("tempPref") === null)
  localStorage.setItem("tempPref", "");
  return localStorage.getItem("tempPref");
};

const changeTempPref = (pref) => {
  localStorage.setItem("tempPref", pref);
};

export const TempPrefContext = createContext(getTempPref());
export const UpdateTempPrefContext = createContext();

export const useTempPref = () => {
  return useContext(TempPrefContext);
};

export const useUpdateTempPref = () => {
  return useContext(UpdateTempPrefContext);
};

export const TempPrefProvider = ({ children }) => {
  const [tempPref, setTempPref] = useState(getTempPref());

  const updateTempPref = (pref) => {
    changeTempPref(pref);
    setTempPref(pref);
  };

  return (
    <TempPrefContext.Provider value={tempPref}>
      <UpdateTempPrefContext.Provider value={updateTempPref}>
        {children}
      </UpdateTempPrefContext.Provider>
    </TempPrefContext.Provider>
  );
};
