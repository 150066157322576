import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc)
dayjs.extend(timezone)

const FormatDateTime = ({ dateTime, type, className }) => {
    const utcDate = dayjs.utc(dateTime);
    let localDateTime;

    if (type === 'short'){
      localDateTime = utcDate.local().format('M/DD h:mm A');
    } else {
      localDateTime = utcDate.local().format('MM/DD/YY h:mm A');
    }
  
    return ( <p className={`${className}`}>{localDateTime}</p> );
  }

export default FormatDateTime