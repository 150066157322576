import { createContext, useContext, useState } from "react";

const getUserRolePref = () => {
  if (localStorage.getItem("rolePref") === null || localStorage.getItem("rolePref") === "RoleGroup")
  localStorage.setItem("rolePref", "");
  return localStorage.getItem("rolePref");
};

const changeUserRolePref = (pref) => {
  localStorage.setItem("rolePref", pref);
};

export const UserRolePrefContext = createContext(getUserRolePref());
export const UpdateUserRolePrefContext = createContext();

export const useUserRolePref = () => {
  return useContext(UserRolePrefContext);
};
export const useUpdateUserRolePref = () => {
  return useContext(UpdateUserRolePrefContext);
};

export const UserRolePrefProvider = ({ children }) => {
  const [rolePref, setRolePref] = useState(getUserRolePref());

  const updateUserRolePref = (pref) => {
    changeUserRolePref(pref);
    setRolePref(pref);
  };

  return (
    <UserRolePrefContext.Provider value={rolePref}>
      <UpdateUserRolePrefContext.Provider value={updateUserRolePref}>
        {children}
      </UpdateUserRolePrefContext.Provider>
    </UserRolePrefContext.Provider>
  );
};
