import { useUser } from "../../auth/UserContext"
import { useAuth0 } from "@auth0/auth0-react";
import { usePutUserAuthRequestV1Mutation } from "../../store/penpalServerAPI";
import { useEffect, useState } from "react";
import EditUserData from "./EditUserData";

const UserData = ({ authReqData }) => {
  const user = useUser();
  const [edit, setEdit] = useState(false);

  useEffect(() => {

  }, [edit, authReqData])

  return (
    <div className="w-full flex flex-col items-center justify-center">
      {edit ? (
        <div className="w-full flex justify-center">
          <EditUserData authReqData={authReqData} setEdit={setEdit} />
        </div>
      )
        : (
          <div className="flex flex-col w-10/12 p-4 rounded-lg bg-stone-50 shadow-lg">
            <div className="flex justify-between border-b">
              <p className="">Email</p>
              <p className="">{authReqData.email}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">First Name</p>
              <p className="">{authReqData.firstName}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Last Name</p>
              <p className="">{authReqData.lastName}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Phone</p>
              <p className="">{authReqData.phone}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Fax</p>
              <p className="">{authReqData.fax}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Institution</p>
              <p className="">{user.InstitutionID}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Roles</p>
              <p className="">{authReqData.roles.split(',').join(', ')}</p>
            </div>
            <div className="w-full flex justify-center mt-4">
              <button className="rounded py-1 px-4 bg-sunburst text-white" onClick={() => setEdit(true)}>Edit</button>
            </div>
          </div>
        )}
    </div>
  );
};

export default UserData;