import { createContext, useContext, useState } from "react";

const getProtocolPref = () => {
  if (localStorage.getItem("protocolPref") === null)
  localStorage.setItem("protocolPref", "");
  return localStorage.getItem("protocolPref");
};

const changeProtocolPref = (pref) => {
  localStorage.setItem("protocolPref", pref);
};

export const ProtocolPrefContext = createContext(getProtocolPref());
export const UpdateProtocolPrefContext = createContext();

export const useProtocolPref = () => {
  return useContext(ProtocolPrefContext);
};
export const useUpdateProtocolPref = () => {
  return useContext(UpdateProtocolPrefContext);
};

export const ProtocolPrefProvider = ({ children }) => {
  const [protocolPref, setProtocolPref] = useState(getProtocolPref());

  const updateProtocolPref = (pref) => {
    changeProtocolPref(pref);
    setProtocolPref(pref);
  };

  return (
    <ProtocolPrefContext.Provider value={protocolPref}>
      <UpdateProtocolPrefContext.Provider value={updateProtocolPref}>
        {children}
      </UpdateProtocolPrefContext.Provider>
    </ProtocolPrefContext.Provider>
  );
};
