const DotsSVG = ({className = "", classDot1 = "", classDot2 = "", classDot3 = ""}) => {
  return(
    <svg
      className={className}
      viewBox="0 0 50 50"
      style={{ width: '100%', height: 'auto' }}
      fill="currentColor" >
        <rect
          className={classDot1}
          width="10"
          height="10"
          x="0"
          y="20" />
        <rect
          className={classDot2}
          width="10"
          height="10"
          x="20"
          y="20" />
        <rect
          className={classDot3}
          width="10"
          height="10"
          x="40"
          y="20" />
    </svg>
  );
}

export default DotsSVG;