import { useEffect, useState } from "react"
import { useUser } from "../../auth/UserContext";
import { usePutUserAuthRequestV1Mutation } from "../../store/penpalServerAPI";

const EditUserData = ({ authReqData, setEdit }) => {
  const user = useUser();
  const [email, setEmail] = useState(authReqData.email);
  const [firstName, setFirstName] = useState(authReqData.firstName);
  const [lastName, setLastName] = useState(authReqData.lastName);
  const [phone, setPhone] = useState(authReqData.phone);
  const [fax, setFax] = useState(authReqData.fax);
  const [institution, setInstitution] = useState(user.InstitutionID ? user.InstitutionID : '');
  const [roles, setRoles] = useState([]);
  const [pi, setPi] = useState('');
  const [vet, setVet] = useState('');
  const [admin, setAdmin] = useState('');
  const [vetStaff, setVetStaff] = useState('');
  const [careStaff, setCareStaff] = useState('');
  const [errors, setErrors] = useState([]);

  const [updateAuthRequest, { data, isLoading, isError, isSuccess, error }] = usePutUserAuthRequestV1Mutation();

  useEffect(()=>{
    if (authReqData.roles.includes('PI')) setPi('PI')
    if (authReqData.roles.includes('VET')) setVet('VET')
    if (authReqData.roles.includes('ADMIN')) setAdmin('ADMIN')
    if (authReqData.roles.includes('VETSTAFF')) setVetStaff('VETSTAFF')
    if (authReqData.roles.includes('CARESTAFF')) setCareStaff('CARESTAFF')
  }, [])

  function updatePi() {
    if (pi) setPi('')
    else setPi('PI')
  }

  function updateVet() {
    if (vet) setVet('')
    else setVet('VET')
  }

  function updateAdmin() {
    if (admin) setAdmin('')
    else setAdmin('ADMIN')
  }

  function updateVetStaff() {
    if (vetStaff) setVetStaff('')
    else setVetStaff('VETSTAFF')
  }

  function updateCareStaff() {
    if (careStaff) setCareStaff('')
    else setCareStaff('CARESTAFF')
  }

  function checkErrors(obj) {
    if (!obj.Email) {
      setErrors([...errors, "Email Required"])
      return false;
    }
    if (!obj.FirstName) {
      setErrors([...errors, "First Name Required"])
      return false;
    }
    if (!obj.LastName) {
      setErrors([...errors, "Last Name Required"])
      return false;
    }
    if (!obj.InstitutionID) {
      setErrors([...errors, "Institution Required"])
      return false;
    }
    if (!obj.Roles) {
      setErrors([...errors, "Role(s) Required"])
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    let newUserData = {
      UserID: authReqData.userId,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Fax: fax,
      InstitutionID: institution,
      Roles: roles.filter(role => role.length).join(',')
    }
    if (checkErrors(newUserData)) {
      await updateAuthRequest(newUserData)
    } else {
      console.log("Required fields missing.")
      console.log(error)
    }
  }

  useEffect(() => {
    setRoles([pi, vet, admin, vetStaff, careStaff])
  }, [pi, vet, admin, vetStaff, careStaff]);

  useEffect(() => {
    setErrors([]);
  }, [email, firstName, lastName, institution, roles])

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [data, isSuccess, isError])

  useEffect(() => {
    if (!institution) {
      let emailDomain = authReqData.email.split('@')[1];
      let x = emailDomain.split('.')[0];
      if (x == 'tricorderarray') {
        setInstitution('TRICORDER')
      } else if (x == 'uab') {
        setInstitution('UAB')
      }
    }
  }, [institution])

  return (
    <>
      <div className="flex flex-col w-10/12 p-4 rounded-lg bg-stone-50 shadow-lg">
        <div className="flex justify-between border-b">
          <label className="">Email</label>
          <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="flex justify-between border-b">
          <label className="">First Name</label>
          <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className="flex justify-between border-b">
          <label className="">Last Name</label>
          <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className="flex justify-between border-b">
          <label className="">Phone</label>
          <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={phone} onChange={(e) => setPhone(e.target.value.replace(/[^0-9.]/g, ''))} />
        </div>
        <div className="flex justify-between border-b">
          <label className="">Fax</label>
          <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={fax} onChange={(e) => setFax(e.target.value.replace(/[^0-9.]/g, ''))} />
        </div>
        <div className="flex justify-between border-b">
          <label className="">Institution</label>
          <select className="min-w-[186px] max-w-[220px] border rounded pl-1" value={institution} onChange={(e) => setInstitution(e.target.value)}>
            <option className="" value={'TRICORDER'}>Tricorder</option>
            <option className="" value={'UAB'}>UAB</option>
          </select>
        </div>
        <div className="flex justify-between my-6">
          <p className="whitespace-nowrap ">Select Roles</p>
          <div className="w-[205px] md:max-w-[210px] flex flex-wrap gap-2">
            <button
              onClick={updatePi}
              className={`border rounded px-3 py-1 ${pi.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}
            >PI</button>
            <button
              onClick={updateVet}
              className={`border rounded px-3 py-1 ${vet.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Veterinarian</button>
            <button
              onClick={updateAdmin}
              className={`border rounded px-3 py-1 ${admin.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Admin</button>
            <button
              onClick={updateVetStaff}
              className={`border rounded px-3 py-1 ${vetStaff.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Vet Staff</button>
            <button
              onClick={updateCareStaff}
              className={`border rounded px-3 py-1 ${careStaff.length ? "bg-sunburst " : "shadow-md bg-white"} transition-all`}>Care Staff</button>
          </div>
        </div>
        <div className="w-full flex justify-around mt-2">
          <button className="rounded py-1 px-4 bg-red-500 text-white" onClick={() => setEdit(false)}>Cancel</button>
          <button className="rounded py-1 px-4 bg-sunburst text-white" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </>
  )
};

export default EditUserData;