import { useEffect, useRef, useState } from "react";

const ModalWrapper = ({ children, isOpen, setIsOpen }) => {
  const container = useRef();
  const inner = useRef();
  const background = useRef();
  const [open, setOpen] = useState(false);

  // useEffect(()=>{
  //   return ()=>{

  //   }
  // }, [])

  useEffect(() => {
    let root = document.getElementById('root');
    if (isOpen) {
      if (root !== null)
        root.classList.add("overflow-hidden");
      root.classList.add("h-full");
      openModal()
    } else {
      if (root !== null) {
        root.classList.remove("overflow-hidden");
        root.classList.remove("h-full");
        closeModal()
      }
    }
  }, [isOpen])

  const openModal = () => {
    if (container.current != null) {
      container.current.classList.remove("hidden");
      inner.current.classList.remove("hidden");
      background.current.classList.remove("hidden");
      setTimeout(() => {
        setOpen(true);
      }, 300);
    }
  };

  const closeModal = () => {
    setOpen(false);
    if (
      container.current === null ||
      !container ||
      inner.current === null ||
      background.current === null
    )
      return;
    else {
      setTimeout(() => {
        if (container.current !== null && inner.current !== null && background.current !== null) {
          container.current.classList.add("hidden");
          inner.current.classList.add("hidden");
          background.current.classList.add("hidden");
        }
      }, 200);
    }
  };

  return (
    <div
      ref={container}
      className={`${open ? "opacity-100" : "opacity-0 -z-[500]"
        } w-screen h-full inset-0 fixed flex justify-center items-center transistion-all duration-300 ease-in-out z-[400]`}
    >
      <div
        ref={inner}
        className={`${open ? "opacity-100" : "opacity-0"
          } max-w-[500px] flex flex-col w-10/12 z-[200] rounded-xl dark:bg-stone-800 bg-stone-50 transition-all duration-300 ease-out`}
      >
        {children}
      </div>
      <div
        ref={background}
        onClick={() => setIsOpen(false)}
        className={`${open ? "opacity-60 z-[150]" : "opacity-0 -z-[500]"
          } h-full fixed inset-0 bg-black transition-all duration-300 ease-in-out`}
      ></div>
    </div>
  );
};

export default ModalWrapper;
