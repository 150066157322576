const HeaderMenuTab = ({ tab, isActive }) => {
  return (
    <div className="flex flex-row gap-1 rounded-md items-center justify-center px-3 py-2">
      <img
        src={tab.icon}
        alt={tab.alt}
        className={`w-[20px] h-auto dark:invert`}
        style={isActive ? {filter: 'invert(78%) sepia(78%) saturate(1437%) hue-rotate(330deg) brightness(97%) contrast(101%)' } : null}
      />
      <p className="font-chaletNineteenSixty text-sm whitespace-nowrap">
        {tab.title}
      </p>
    </div>
  );
};

export default HeaderMenuTab;