import { useIsAuthenticated } from "@azure/msal-react";
import CheckAuthorized from "../auth/CheckAuthorized";
import LoadingScreen from "../components/global/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRedirectUser, useUpdateRedirect } from "../components/context/RedirectContext";

const LoginRedirect = () => {
  const navigate = useNavigate();
  const updateRedirect = useUpdateRedirect();
  const redirectUserState = useRedirectUser();

  useEffect(()=>{
    if (window.location.pathname.length > 1) {
      updateRedirect(window.location.pathname)
      return navigate('/')
    }
  }, [window])

  return <LoadingScreen />;
};

export default LoginRedirect;