import { useSetUser, useUser } from "./UserContext";
import { useEffect, useState } from "react";
import LoadingScreen from "../components/global/LoadingScreen";
import penpalLogo from "../images/penPalText.png";
import CreateAccount from "../pages/CreateAccount";
import CheckRole from "./CheckRole";
import { useUpdateUserRolePref, useUserRolePref } from "../components/context/UserRoleContext";
import { useGetUserByEmailV1Query, useLazyGetUserByEmailV1Query, useLazyGetUserRolesV1Query } from "../store/penpalServerAPI";
import Unauthorized from "../roles/Unauthorized";
import CommonHeader from "../components/global/CommonHeader";
import UserMenu from "../components/global/Sidebar";
import { BrowserRouter, Router, Routes } from "react-router-dom";

const CheckAuthorized = ({ email = '', loading }) => {
  const updateUser = useSetUser();
  const user = useUser();
  const rolePref = useUserRolePref();
  const updateUserRolePref = useUpdateUserRolePref();
  const [myRoles, setMyRoles] = useState([]);

  const [getUserByEmail, { data,
    isError,
    isSuccess,
    error,
    isLoading
  }] = useLazyGetUserByEmailV1Query();

  const [getUserRoles, {
    data: rolesData,
    isError: rolesIsError,
    isSuccess: rolesIsSuccess,
    isLoading: rolesIsLoading,
    error: rolesError
  }] = useLazyGetUserRolesV1Query();

  useEffect(() => {
    if (email.length) {
      getUserByEmail(email);
    }
  }, [email]);

  useEffect(() => {
    if (isSuccess && data.length) getUserRoles(data[0].userId);
    else if (isError) {
      console.log(`${error.status}: `, error.message)
    }
  }, [data, isSuccess, isError, error]);

  // const fetchUserRoles = async (userId) => {
  //   let res = await fetch(`${veryBasedUrl}api/v2/Users/${userId}/roles`, {
  //     method: 'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Authorization": `Bearer ${token}`
  //     }
  //   })
  //   let roleData = await res.json();
  //   setRolesData(roleData)
  //   console.log('Roles DATA: ', roleData)
  // }

  useEffect(() => {
    if (rolesIsSuccess) {
      let roles = rolesData.map(obj => {
        return obj.roleName;
      })
      setMyRoles(roles)
    }
  }, [rolesData, rolesIsSuccess]);

  useEffect(() => {
    if (data === undefined) return;
    if (!data.length) return;
    if (rolesIsError || isError) return;
    if (rolesData && myRoles.length) {
      let myInstitution = ''
      if (data[0].email.includes('@tricorderarray.com')) {
        myInstitution = 'TRICORDER'
      }
      if (data[0].email.includes('@uab.edu')) {
        myInstitution = 'UAB'
      }
      let myRole = ''
      if (rolePref === 'PI') {
        myRole = 'PI';
      } else if (rolePref === 'VET') {
        myRole = 'VET';
      } else if (rolePref === 'ADMIN') {
        myRole = 'ADMIN';
      }else if (myRoles.includes('PI')) {
        myRole = 'PI'
        updateUserRolePref('PI')
      } else if (myRoles.includes('VET')) {
        myRole = 'VET'
        updateUserRolePref('VET')
      }else if (myRoles.includes('ADMIN')) {
        myRole = 'ADMIN'
        updateUserRolePref('ADMIN')
      } else if (myRoles.includes(rolePref)) {
        myRole = rolePref;
      } else {
        myRole = myRoles[0]
        updateUserRolePref(myRole)
      }
      updateUser({
        Authorized: data[0].authorized,
        DateTimeCreated: data[0].dateTimeCreated,
        Email: data[0].email,
        FirstName: data[0].firstName,
        LastName: data[0].lastName,
        ID: data[0].userId,
        InstitutionID: myInstitution,
        Phone: data[0].phone,
        Roles: myRoles,
        SelectedRole: myRole,
      });
    }
  }, [data, rolePref, myRoles, rolesData]);

  useEffect(() => {
  }, [user])

  function reloadWindow() {
    window.document.location.reload()
  }

  // if (typeof data === 'object') {
  //   if (!data.length) return <Unauthorized />;
  // }

  // if (isLoading || loading) {
  //   return (
  //     <div className="w-screen h-screen px-2 flex flex-col justify-center items-center my-4">
  //       <img
  //         src={penpalLogo}
  //         alt="penpal"
  //         className="w-11/12 max-w-[400px] mb-16 -mt-60"
  //       />
  //       <LoadingScreen />
  //     </div>
  //   );
  // }

  if (isError && error.status === 500) return (
    <BrowserRouter>
      <div className="w-full h-screen flex flex-col items-center">
        <CommonHeader />
        <div className="flex flex-col justify-center items-center w-full h-[80vh]">
          <div className="flex justify-between items-center w-[240px]">
            <p className="text-3xl font-bold dark:text-white">500</p>
            <p className="text-2xl dark:text-white">|</p>
            <p className="font-bold dark:text-white">Internal Server Error</p>
          </div>
          <div className="mt-10">
            <button
              className="px-3 py-2 bg-sunburst text-white rounded"
              onClick={reloadWindow}
            >
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </BrowserRouter>
  )
  if (isLoading || rolesIsLoading) return <LoadingScreen />
  if (user.Authorized) return <CheckRole />
  else if (isError || rolesIsError) return <Unauthorized />;
};

export default CheckAuthorized;
