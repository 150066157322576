import { createContext, useContext, useState } from "react";

const getAuthContext = () => {
  if (localStorage.getItem("authProvider") === null)
    localStorage.setItem("authProvider", "");
  return localStorage.getItem("authProvider");
};

const changeAuthContext = (str) => {
  localStorage.setItem("authProvider", str);
};

export const AuthContext = createContext();
export const UpdateAuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useUpdateAuthContext = () => {
  return useContext(UpdateAuthContext)
;}

export const AuthContextProvider = ({ children }) => {
  const [authority, setAuthority] = useState(getAuthContext());

  const updateAuthority = (x) => {
    changeAuthContext(x)
    setAuthority(x)
  }

  return (
    <AuthContext.Provider value={authority}>
      <UpdateAuthContext.Provider value={updateAuthority}>
        {children}
      </UpdateAuthContext.Provider>
    </AuthContext.Provider>
  );
};