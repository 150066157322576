import { useUser } from "../../auth/UserContext";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bell from "../../icons/notification.png";
import useNotifications from "../../hooks/useNotifications";
import NotificationItem from "../sections/NotificationItem";
import { useGetUserProtocolsV1Query } from "../../store/penpalServerAPI";
import { useUpdateSelectedNotif } from "../context/NotifContext";

const NotificationsMenu = ({ tools = [] }) => {
  const user = useUser();
  const toolbar = useRef(null);
  const background = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [atNotificationsPage, setAtNotificationsPage] = useState(false);
  const updateSelectedNotif = useUpdateSelectedNotif();

  const { data: protocolData, isSuccess: protocolsIsSuccess } = useGetUserProtocolsV1Query(user.ID);

  const {
    notifs,
    refetchNotifsInfo,
    refetchNotifTypesInfo,
  } = useNotifications();

  useEffect(() => {
    let timeoutId;

    const fetchNotifications = () => {
      refetchNotifsInfo();
      refetchNotifTypesInfo();
      timeoutId = setTimeout(fetchNotifications, 10000);
    };

    fetchNotifications();

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    let url = window.location.pathname.split("/");
    setAtNotificationsPage(url[1] === "notifications");
  }, []);

  useEffect(() => {
    let root = document.getElementById("root");
    if (background.current == null || toolbar.current == null) return;
    else {
      if (!openMenu) {
        root.classList.remove("overflow-hidden");
        root.classList.remove("h-screen");
        setOpen(false);
        setTimeout(() => {
          background.current?.classList?.add("hidden");
          toolbar.current?.classList?.add("hidden");
        }, 250);
      } else {
        root.classList.add("overflow-hidden");
        root.classList.add("h-screen");
        setOpen(true);
        background.current?.classList?.remove("hidden");
        toolbar.current?.classList?.remove("hidden");
      }
    }
  }, [openMenu]);

  const closeModal = () => {
    setOpenMenu(false)
  };
  
  const handleVisitNotificationsPage = () => {
    updateSelectedNotif(null)
    setOpenMenu(false)
  }

  const colorFromType = (level) => {
    switch (level) {
      case 1:
        return "border-orange-100 bg-orange-400 text-black"; 
      case 2:
        return "border-yellow-100 bg-yellow-400 text-black"; 
      case 3:
        return "border-red-300 bg-red-500 text-black";
      case 4:
        return "border-red-500 bg-red-700 text-black";
    }
  };
 
  const activeNotifs = notifs.filter((notif) => notif.active);
  const sortedNotifs = activeNotifs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  const filteredNotifs = sortedNotifs.filter((c) => {
    const userProtocolMatch = protocolData?.some(protocol => protocol.protocolId === c.protocolId);
    return userProtocolMatch;
  });
  
  const displayedNotifs = filteredNotifs.slice(0, 10);

  return (
    <>
      <button
        className={`h-[45px] flex items-center mr-2 `}
        onClick={() => {
          if (atNotificationsPage) {
            navigate("/notifications");
          } else {
            setOpenMenu(true);
          }
        }}
      >
        <img src={bell} alt="Notifications" className="relative mr-2 h-[30px] w-[30px]" />
        {filteredNotifs.length > 0 && (
          <div className="position: absolute right-12 top-5 h-[18px] min-w-[24px] bg-red-600 text-white rounded-full text-xs flex justify-center items-center">
            {filteredNotifs.length}
          </div>
        )}
      </button>

      <div ref={background} className="absolute hidden h-screen w-screen inset-0 backdrop-blur-sm z-20 bg-black/40 transition-opacity duration-200 ease-in-out" onClick={closeModal}></div>
      <div ref={toolbar} className="hidden fixed top-1/2 left-1/2 w-11/12 md:w-[600px] transform -translate-x-1/2 -translate-y-1/2 z-30">
        <div className={`h-[500px] transition-all duration-300 ease-in-out ${open ? "translate-y-0" : "-translate-y-full"} flex flex-col items-center bg-slate-200 dark:bg-slate-800 border dark:border-slate-600 rounded-lg shadow-lg w-full p-4`}>
          <div className="w-full h-full flex justify-between items-center mb-4">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-sunburst font-magBold">Notifications</h3>
            <button onClick={closeModal} className="text-gray-600 dark:text-gray-300">
              Close
            </button>
          </div>
          <div className="w-full h-full p-1 rounded-md bg-stone-300 dark:bg-slate-900">
            <div className="w-full h-[350px] overflow-y-auto">
              {displayedNotifs.map((notif) => (
                <NotificationItem key={notif.id} notif={notif} colorFromType={colorFromType} atNotificationsPage={atNotificationsPage} />
              ))}
            </div>
          </div>
          <Link to="/notifications" onClick={closeModal} className="w-full flex justify-center mt-4">
            <button className="w-11/12 py-2 bg-sunburst text-white border border-sunburst rounded-lg hover:bg-sunburst">
              See All Notifications
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotificationsMenu;
