import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DarkModeProvider } from "./components/context/ThemeContext";
import { UserProvider } from "./auth/UserContext";
import { msalInstance } from "./auth/utils";
import { MsalProvider } from "@azure/msal-react";
import { UserRolePrefProvider } from "./components/context/UserRoleContext";
import { RedirectUserProvider } from "./components/context/RedirectContext";
import { AuthContextProvider } from "./auth/AuthContext";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { ProtocolPrefProvider } from "./components/context/ProtocolContext.js";
import { TokenContextProvider } from "./auth/TokenContext.js";
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.all';
import { TempPrefProvider } from "./components/context/TempContext.js";
import { SelectedNotifProvider } from "./components/context/NotifContext.js";

const auth0Config = {
  "domain": "auth.penpal.day",
  "clientId": "qnSvkjt6xMSnUUnSAzO2QYhIwuXUL00i",
  "audience": "https://penpalserver.azurewebsites.net/api",
  "scope": "openid profile email offline_access"
  //https://dev-t0d101w8fkkvkfss.auth0.com/userinfo
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <Auth0Provider
        domain="auth.penpal.day"
        clientId="qnSvkjt6xMSnUUnSAzO2QYhIwuXUL00i"
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
        authorizationParams={{
          redirect_uri: window.location.origin,
          ...(auth0Config.audience ? { audience: auth0Config.audience, scope: auth0Config.scope } : null),
        }}
      >
        <MsalProvider instance={msalInstance}>
          <RedirectUserProvider>
            <TokenContextProvider>
              <ProtocolPrefProvider>
                <SelectedNotifProvider>
                  <TempPrefProvider>
                    <UserRolePrefProvider>
                      <UserProvider>
                        <DarkModeProvider>
                          <I18nProvider local={"en"} messages={[messages]}>
                            <Provider store={store}>
                              <App />
                            </Provider>
                          </I18nProvider>
                        </DarkModeProvider>
                      </UserProvider>
                    </UserRolePrefProvider>
                  </TempPrefProvider>
                </SelectedNotifProvider>
              </ProtocolPrefProvider>
            </TokenContextProvider>
          </RedirectUserProvider>
        </MsalProvider>
      </Auth0Provider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
